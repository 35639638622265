@font-face {
    font-family: "Recoleta";
    font-style: normal;
    font-weight: 100;
    src: url("../fonts/RecoletaThin/font.woff2") format("woff2"),
        url("../fonts/RecoletaThin/font.woff") format("woff");
}
@font-face {
    font-family: "Recoleta";
    font-style: normal;
    font-weight: 200;
    src: url("../fonts/RecoletaLight/font.woff2") format("woff2"),
        url("../fonts/RecoletaLight/font.woff") format("woff");
}

@font-face {
    font-family: "Recoleta";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/RecoletaRegular/font.woff2") format("woff2"),
        url("../fonts/RecoletaRegular/font.woff") format("woff");
}

@font-face {
    font-family: "Recoleta";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/RecoletaMedium/font.woff2") format("woff2"),
        url("../fonts/RecoletaMedium/font.woff") format("woff");
}

@font-face {
    font-family: "Recoleta";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/RecoletaSemiBold/font.woff2") format("woff2"),
        url("../fonts/RecoletaSemiBold/font.woff") format("woff");
}

@font-face {
    font-family: "Recoleta";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/RecoletaBold/font.woff2") format("woff2"),
        url("../fonts/RecoletaBold/font.woff") format("woff");
}
